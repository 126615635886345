import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgArrowCircleDown = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M11 8a1 1 0 1 1 2 0v5h3l-4 4-4-4h3V8Z" />,
    <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z" />
  )
export default SvgArrowCircleDown
